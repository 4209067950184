

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const UPDATE_INVOICE = gql`
  mutation UpdateConsultantInvoice($updatedInvoice: UpdateConsultantInvoiceRequestDto!) {
    updateConsultantInvoice(updatedInvoice: $updatedInvoice) {
      uuid
      invoiceDate
      periodStart
      periodEnd
      clientName
      invoiceNumber
      currency
      dailyRateConsultant
      dailyRateClient
      daysWorked
      commission
      manualCommission
    }
  }
`;

export const useUpdateInvoice = () => {
  const { user } = useAuth();

  const [updateInvoice, { loading, error, data }] = useMutation(UPDATE_INVOICE, {
    context: {
      headers: {
        Authorization: `Bearer ${user.strongToken}`,
      },
    },
  });

  return { updateInvoice, loading, error, data };
};
