import * as React from "react"

import { cn } from "@/lib/utils"

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    (<textarea
      className={cn(
        "flex min-h-[80px] justify-beetween w-full rounded-md border  px-3 py-2 text-body ring-offset-primary  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-medium focus-visible:ring-offset-2 disabled:cursor-not-allowed bg-white disabled:bg-medium disabled:border-grey disabled:text-disabled",
        className
      )}
      ref={ref}
      {...props} />)
  );
})
Textarea.displayName = "Textarea"

export { Textarea }
