// Rôle requis : ADMIN_TIMESHEET
// Ce hook permet à un administrateur de valider ou de refuser une timesheet.
// Seuls les utilisateurs ayant le rôle ADMIN_TIMESHEET peuvent accéder à cette mutation.

import { gql, useMutation } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";

const APPROVE_TIMESHEET = gql`
  mutation ApproveTimesheet($uuid: ID!, $approvalStatus: TimesheetApprovalStatus!, $adminComment: String) {
    approveTimesheet(approvedTimesheet: { uuid: $uuid, approvalStatus: $approvalStatus, adminComment: $adminComment})
  }
`;

export const useApproveTimesheet = () => {
  const { user } = useAuth();
  const [approveTimesheetMutation, { loading, error, data }] = useMutation(APPROVE_TIMESHEET, {
    context: {
      headers: {
        Authorization: `Bearer ${user?.strongToken}`,
      },
    },
  });

  const approveTimesheet = async (uuid, approvalStatus,adminComment) => {
    // console.log("approveTimesheet uuid, approvalStatus=", uuid, approvalStatus)
    
    try {
      const response = await approveTimesheetMutation({
        variables: { uuid, approvalStatus, adminComment },
      });
      return { data: response.data?.approveTimesheet, error: null };
    } catch (err) {
      return { data: null, error: err.message };
    }
  };

  return { approveTimesheet, loading, error, data };
};
