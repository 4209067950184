
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogOverlay } from '@/components/ui/dialog';
import { Label } from '@/components/atoms/texts/label';
import { Button } from '@/components/molecules/buttons/button';

export default function DialogConfirmDelete({ open, onClose, onConfirm, title = undefined }) {
    return (
        <Dialog open={open} onOpenChange={onClose}>
            {/* <DialogOverlay /> */}
            <DialogContent>
                <DialogTitle>
                    <Label message="global.actions.askConfirmDeleteTitle"></Label>
                </DialogTitle>
                
                    <Label message="global.actions.askConfirmDelete"></Label>
                    <Label fontWeight="bold">{title}</Label>
                
                <DialogFooter>
                    <Button onClick={onClose} style="ghost" text="global.actions.cancel" />
                    <Button onClick={onConfirm} className="text-white bg-error font-bold hover:bg-destructive" text="global.actions.confirmDelete" />
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}