/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { Label } from '@/components/atoms/texts/label';
import SvgEffectOnNavItem from '@/components/atoms/SvgEffectOnNavItem';
import { useNavigate } from 'react-router-dom';
import { Button } from '../buttons/button'
import { useNavbar } from '@/components/organisms/navigation/header/useNavBar';
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/context/AuthContext';



export default function NavItem({ name, selected, path, handleNavItemClick, disabled = false }) {

    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const { isAvailableRoute } = useAuth();

    const [disabledFor, setDisabledFor] = useState(false);
    

    useEffect(() => {
        // Si la route n'est pas disponible, on cherche la cause dee la non disponibilité
        // Si la route n'est pas disponible (si isAvailableRoute est false) on met le disabledFor à NOT_AVAILABLE , 
        // sinon on le set à NOT_ALLOWED 
        // Cela permet de gérer le disabled state de manière dynamique en fonction de la route disponible ou non    
        if (disabled) {
            if (!isAvailableRoute(path)) {
                setDisabledFor("NOT_AVAILABLE");
            } else {
                setDisabledFor("NOT_ALLOWED");
            }
        } else {
            setDisabledFor(undefined);
        }



    }, []);

    return (

        <div className={` ${selected ? 'text-secondary  bg-hover font-[700]  xl:font-bodySmall xl:border-b-4 xl:border-secondary' : ''}
     h-[57px] w-full    xl:h-full xl:p-paddingTopAndBottom   xl:w-auto  ${!disabled ? `cursor-pointer hover:bg-hover` : `cursor-not-allowed`}
      rounded-md xl:rounded-tl-[0px] xl:rounded-tr-[0px] xl:rounded-bl-[0px] xl:rounded-br-[0px] flex items-center justify-center
      ${disabled && `bg-[#ffffff05] text-greyStrong `} `}
            onClick={!disabled ? (() => handleNavItemClick(path)):undefined}>
            {

                <div className={` sm:p-[16px] xl:py-[0px] w-full xl:w-auto px-paddingLeftAndRight flex-col`}>
                    <Tooltip title={disabled ? (disabledFor === "NOT_AVAILABLE" ? t("error.underConstruction") : t("error.noRights")) : ""} placement="top" arrow>
                        <Label size='bodySmall' className={`hidden xl:block py-[5px]${disabled && `cursor-not-allowed font-thin`}`} message={name} />
                        <Label size='bodySmall' className={`xl:hidden block text-center box-border border-primary rounded-md ${disabled && `cursor-not-allowed `}`} message={name} />
                    </Tooltip>
                    {selected && <SvgEffectOnNavItem />}

                </div>


            }

        </div>
    );
}
