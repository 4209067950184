import { useState, useEffect } from "react";
import useUser from "@/services/admin-services/query/useUser";
import useAllConsultantProfiles from "@/services/admin-services/query/useAllConsultantProfiles";
import useAllRetributionModels from "@/services/admin-services/query/useAllRetributionModels";
import { useAuth } from "@/context/AuthContext";

const useInformationData = (id) => {
    const [informationData, setInformationData] = useState(null);
    const [error, setError] = useState(null);
    const { hasPrivileges} = useAuth();

    const { getUser, user, loading: userLoading, error: userError, refetch:refetchUserInformation } = useUser(id);
    const { consultantProfiles, loading: profilesLoading, error: profilesError } = useAllConsultantProfiles();
    const { retributionModels, loading: modelsLoading, error: modelsError } = hasPrivileges("ADMIN_USER_INFO_FULL") && useAllRetributionModels();

    useEffect(() => {
        // console.log('useInformationData user change detected', user, consultantProfiles, retributionModels);
        if (user) {
            setInformationData((prevData) => ({
                ...prevData,
                ...user,
            }));
        }
        if (consultantProfiles) {
            setInformationData((prevData) => ({
                ...prevData,
                allConsultantProfiles: consultantProfiles,
            }));
        }
        if (retributionModels) {
            setInformationData((prevData) => ({
                ...prevData,
                allRetributionsModels: retributionModels,
            }));
        }
        setError({
            userError,
            profilesError,
            modelsError,
        });

    }, [user, consultantProfiles, retributionModels]);
    
    useEffect(() => {
        // console.log('fetch data for user', id);
        const fetchData = async () => {
             getUser(id);
            if (userLoading) {return}
            if (user) {
                setInformationData(user);
            } else {
                console.error("Error fetching user:", userError, "user =", user,  "userLoading =", userLoading, id);
                setError({ errorsBackEnd: userError });
            }
        };

         fetchData();
    }, [id]);

    return { informationData, error, userError, profilesError, modelsError, userLoading, profilesLoading, modelsLoading, refetchUserInformation };
};

export default useInformationData;
