import { useState } from 'react';
import errorToText from '@/utils/errorToText';

const useRequestPasswordResetToken = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const requestPasswordResetToken = async (username) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/security-provider/api/public/users/requestPasswordResetToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({username})
            });

            if (response.status != 200) {
                const errMessage = errorToText(response);
                // console.log(response)
                setLoading(false);
                throw new Error(errMessage);
            }

            //const data = await response.json();
            setLoading(false);
            return { success: true, error: null };
        } catch (err) {
            setError(err.message);
            setLoading(false);

        } return { success: false, error: err.message };

    };

    return { requestPasswordResetToken };
};

export default useRequestPasswordResetToken;
