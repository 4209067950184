/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/**
 * @component DataCell
 *
 * The `DataCell` component is used to render a cell in a data table with customizable styling, hover effects, and tooltips. It supports displaying text or numeric data with conditional formatting and interactivity.
 *
 * Key Features:
 * - Renders a table cell with customizable styles, borders, backgrounds, and padding.
 * - Supports conditional display of hover effects, icons, and tooltips.
 * - Allows different content types (text or number) and conditional formatting based on data type.
 * - Displays additional hover content and icons based on user roles and hover state.
 *
 * Props:
 * - @param {string|number} data - The data to be displayed in the cell. Can be text or a number.
 * - @param {string} [border="none"] - Defines the border style for the cell. Options: "none", "all", "top", "bottom", "left", "right".
 * - @param {string} [padding="default"] - Defines the padding for the cell. Options: "default", "betweenText", "textAtEnd".
 * - @param {string} [background="primaryLinear"] - Defines the background style for the cell. Options: "primaryLinear", "grey", "medium", "primaryLight", "transparent".
 * - @param {boolean} [enableHover=false] - Determines if hover effects are enabled.
 * - @param {string} [contentType="text"] - Defines the content type of the cell. Options: "text" or "number".
 * - @param {string} [className=""] - Additional custom CSS classes to apply to the cell.
 * - @param {Function} [onHoverChange] - Callback function to handle hover state changes.
 * - @param {string} [sizeText="h6"] - Defines the text size for the cell content. Options: "h1", "h2", "h3", "h4", "h5", "h6", "body", "small", "caption".
 * - @param {string} [textColor="black"] - Defines the text color for the cell content. Options: "white" or "black".
 * - @param {string} [Id] - Optional ID for the cell.
 * - @param {boolean} [printToolTip=false] - Determines if a tooltip should be displayed for the cell content.
 * - @param {Object} [userMonthdata] - Additional user month data for context.
 * - @param {React.ReactNode} [icon] - Optional icon to display in the cell.
 * - @param {...Object} [props] - Other props to be applied to the base element.
 *
 * Local State:
 * - `idCell` (string|null): The ID of the cell currently hovered over.
 * - `hover` (boolean): Indicates if the cell is being hovered over.
 * - `pencilHovered` (boolean): Indicates if the pencil icon is being hovered over.
 *
 * Computed Values:
 * - `isMonthName(str)` - Determines if the given string represents a month name.
 * - `dataCellVariants` - Utility function for managing different CSS variants for the cell.
 *
 * Usage:
 * - This component is used to render individual cells within a table, with support for styling, interactivity, and tooltips. It can be customized to handle different content types and display additional hover content.
 *
 * Example:
 * ```jsx
 * <DataCell
 *   data="January"
 *   border="bottom"
 *   background="primaryLight"
 *   enableHover={true}
 *   contentType="text"
 *   sizeText="h5"
 *   textColor="black"
 *   printToolTip={true}
 *   icon={<SomeIcon />}
 *   onHoverChange={() => console.log('Hover changed')}
 * />
 * ```
 *
 * Dependencies:
 * - @component Label: Component for displaying text labels with customizable size and weight.
 * - @component HoverCard: Component for displaying additional content on hover.
 * - @mui/icons-material/PlayArrow: Icon used in the hover card content.
 * - @mui/material/Tooltip: Component for displaying tooltips.
 * - @context AuthContext: Provides user authentication context.
 * - @utils formatDateString: Utility functions for formatting date strings.
 *
 * Functions:
 * - `isMonthName(str)`: Determines if the given string is a month name.
 *
 * @example
 * <DataCell
 *   data="March"
 *   border="top"
 *   background="grey"
 *   enableHover={true}
 *   contentType="text"
 *   sizeText="body"
 *   textColor="white"
 *   printToolTip={true}
 *   userMonthdata={{}}
 *   icon={<CustomIcon />}
 *   onHoverChange={() => console.log('Hover state changed')}
 * />
 * ```
 */


import { cn } from "@/lib/utils"; // Assuming this utility exists for concatenating class names
import { Label } from "@/components/atoms/texts/label"; // Assuming this is your Label component
import { cva } from "class-variance-authority";

import Polygon1 from "../../../assets/Polygon1.svg";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "@/components/ui/hover-card";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DisplayHoverContent from "./DisplayHoverContent";

import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "@/context/AuthContext";
import { isNumeric } from "@/utils/formatDateString";

const baseClass =
  "flex inline-flex items-center text-center  gap-4 font-500 text-h7 relative  truncate ";

const dataCellVariants = cva(baseClass, {
  variants: {
    border: {
      none: "rounded-none",
      all: "rounded-sm",
      top: "rounded-t-sm",
      bottom: "rounded-b-sm",
      left: "rounded-l-sm",
      right: "rounded-r-sm",
    },
    background: {
      primaryLinear: "bg-horizontalPrimaryLinear text-white",
      grey: "bg-grey text-black ",
      medium: "bg-medium text-black ",
      primaryLight: "bg-bgLight text-primary",
      transparent: "bg-transparent text-black",
    },
    padding: {
      default: "px-paddingTopAndBottom py-paddingSmallLeftAndRight",
      betweenText: "p-betweenText",
      textAtEnd:
        "flex-row-reverse items-center gap-4 px-paddingSmallLeftAndRight py-paddingTopAndBottom",
    },
    sizeText: {
      h1: "text-h1",
      h2: "text-h2",
      h3: "text-h3",
      h4: "text-body",
      h5: "text-body",
      h6: "text-bodySmall ",
      h7: "text-bodySmall",
      h8: "text-bodySmall",
      body: "text-body",
      small: "text-bodySmall",
      caption: "text-caption",
    },
    textColor: {
      white: "text-white",
      black: "text-black",
    },
  },
  defaultVariants: {
    border: "none",
    background: "primaryLinear",
    padding: "default",
    sizeText: "h6",
    textColor: "black",
  },
});
//this function helps to make the title of the table be bold
function isMonthName(str) {
  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  // Extraction du nom du mois de la chaîne
  const monthName = str.split(".").pop().toLowerCase();

  return monthNames.includes(monthName);
}

const DataCell = ({
  data,
  border,
  padding,
  background,
  enableHover = false,
  contentType = "text",
  className,
  onHoverChange,
  sizeText = "h6",
  textColor,
  Id,
  printToolTip = false,
  userMonthdata,
  icon, // Additional custom class names
  ...props // Other props for the base element
}) => {
  const { user } = useAuth();

  return (
    <HoverCard>
      <HoverCardTrigger
        className={cn(
          dataCellVariants({
            border,
            background,
            padding,
            sizeText,
            textColor,
          }),
          className,
          "relative ",
          padding != "textAtEnd" ? "justify-center" : ""
        )}
        {...props}
        onMouseEnter={onHoverChange}
      >
        <div className="truncate">
          {enableHover && (
            <div className="absolute left-0 top-0 ">
              <img src={Polygon1} />
            </div>
          )}
          {/* if it is number display  */}
          {/* <span className={`text-${sizeText}`}></span> */}
          {printToolTip ? (
            <Tooltip title={data} arrow>
              <Label size={sizeText}>
                {!(contentType === "text") && data}{" "}
              </Label>
            </Tooltip>
          ) : (
            <Label size={sizeText}>{!(contentType === "text") && data} </Label>
          )}

          {icon}
          {contentType === "text" && (
            <Label
              size={sizeText}
              fontWeight={isMonthName(data) ? "" : "bold"}
              fontFamily=""
              message={data}
              color={textColor}
            />
          )}
        </div>
      </HoverCardTrigger>
      {enableHover && (
        <HoverCardContent
          align="left"
          // className=' w-full mt-[-70px] ml-[-100%] relative grow  px-secondDegree py-paddingTopAndBottom'
          className={`${
            user.role == "admin"
              ? "ml-[-90%] 2xl:ml-[-109%]"
              : "ml-[-98%] 2xl:ml-[-117%]"
          } w-full mt-[-56px]  relative grow  px-secondDegree py-paddingTopAndBottom `}
        >
          <DisplayHoverContent Id={Id} userMonthdata={userMonthdata} />
          <PlayArrowIcon className="absolute z-50 top-7 right-0 text-[#FFF]" />
        </HoverCardContent>
      )}
    </HoverCard>
  );
};

export default DataCell;
