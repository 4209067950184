/**
 * @component StatementTabUser
 *
 * The `StatementTabUser` component displays financial or performance statements for a user (usually a consultant). It retrieves yearly data and provides a user-friendly interface to filter, view, and analyze this data through various UI elements such as tables, charts, and filters.
 *
 * Key Features:
 * - Fetches yearly financial statement data for the authenticated user (consultant).
 * - Allows filtering data by year using a dropdown menu.
 * - Displays detailed data in a table and provides a visual overview using a line chart.
 * - Handles loading states with skeletons and error messages.
 * - Integrates with bill management for consultants via the `BillsMainConsultant` component.
 * - Displays customizable labels and separators for organizing the data layout.
 *
 * Props:
 * - None. The component utilizes authentication context and data fetched via hooks.
 *
 * State:
 * - @variable {String} selectedYear - Stores the currently selected year for filtering statement data.
 * - @variable {Array} allYearlyData - Stores all yearly data fetched from the backend for the consultant.
 * - @variable {Object} yearData - Stores data for the currently selected year.
 *
 * Hooks/Effects:
 * - @hook {useAuth} - Retrieves the authenticated user and their strong token.
 * - @hook {useStatementYears} - Fetches the available years of statements for the authenticated user.
 * - @hook {useStatement} - Fetches the financial statement data for a given year.
 * - @effect Fetches available years when the component mounts or when the user's token changes.
 * - @effect Fetches statement data for each available year when the available years are loaded.
 * - @effect Updates the displayed data when a specific year is selected by the user.
 *
 * Conditional Rendering:
 * - Displays a skeleton loader while data is being fetched.
 * - Displays an error message if there is an issue fetching data.
 * - Displays a filter to select a year and manages the integration of bill-related functionalities.
 * - Renders detailed table and chart components when data is available.
 *
 * Functions:
 * - @function fetchYears - Fetches the available years for the user's statements.
 * - @function fetchData - Fetches statement data for each year and stores it in `allYearlyData`.
 * - @function setYearData - Updates the displayed data based on the selected year.
 *
 * Usage:
 * This component is used in consultant interfaces to view their performance or financial data. It provides tools to filter data by year, view detailed information, and interact with billing information. It is specifically tailored for consultant-level views.
 *
 * Example:
 * <StatementTabUser />
 *
 * Dependencies:
 * - React hooks (`useState`, `useEffect`) for managing state and lifecycle events.
 * - Custom hooks (`useAuth`, `useStatementYears`, `useStatement`) for fetching data.
 * - UI components such as `Label`, `Separator`, `Filter`, `ListOfEditableOverview`, `TableOverflow`, and `LineChartComp` for rendering various parts of the interface.
 * - Error handling component (`PrintErrorMessage`) for displaying errors.
 *
 * 
 * 
 * 
 */

import { Label } from "@/components/atoms/texts/label";
import { Separator } from "@/components/atoms/divider/separator";
import ListOfEditableOverview from "@/components/organisms/ListOfEditableOverview";
import { useState, useEffect } from "react";
import TableOverflow from "@/components/organisms/TableOverflow";
import Filter from "@/components/organisms/Filter";
import { useAuth } from "@/context/AuthContext";
import LineChartComp from "@/components/organisms/charts/LineChartForStatementTab";
import SkeletonHandler from "@/components/templates/skeletons/SkeletonHandler";
import { Toaster } from "@/components/organisms/feedback/toaster";
import useStatement from "@/services/consultant-services/useStatement";
import useStatementYears from "@/services/consultant-services/useStatementYears";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import BillsMainConsultant from "@/components/macro-organisms/panels/panels-consultant/BillsConsultant/BillsMainConsultant";
export default function StatementTabUser() {
    const { user, hasPrivileges } = useAuth();
    const { getYears, allYears, errorYear } = useStatementYears();

    const { getAllStatamentData, error } = useStatement();
    const [allYearlyData, setAllYearlyData] = useState([]);
    const [yearData, setYearData] = useState({});

    const [selectedYear, setSelectedYear] = useState("");

    useEffect(() => {
        const fetchYears = async () => {
            if (user.strongToken) {
                await getYears(user.strongToken);
                // console.log("fetching data", allYears);
            }
        };
        fetchYears();
        // if (user.strongToken) getYears(user.strongToken);
    }, [user.strongToken]);
    //faire une requête pour chaque années disponible dans allYears
    useEffect(() => {
        if (user.strongToken && allYears) {
            const fetchData = async () => {
                const data = [];
                for (const year of allYears) {
                    const result = await getAllStatamentData(user.strongToken, year);
                    if (result.data) {
                        data.push(result.data);
                    }
                }
                setAllYearlyData(data);
            };
            fetchData();
        }
    }, [user.strongToken, allYears]);

    useEffect(() => {
        // Sélectionner les données de l'année cliquée
        if (allYearlyData) {
            const selectedData = allYearlyData.find(
                (item) => item.year === parseInt(selectedYear)
            );
            //   console.log("selected data", selectedData);
            setYearData(selectedData);
        }
    }, [selectedYear, allYearlyData]);

    if (errorYear || error) {
        return <PrintErrorMessage message={error} />;
    }
    
    if (!allYears || !allYearlyData) {
        return (
            <div className="grid grid-cols-2">
                <SkeletonHandler name={"overview"} />
                <SkeletonHandler name={"overflow"} />
            </div>
        );
    }

    return (
        <div className="h-[100%] w-[100%] flex flex-col gap-1 statementTab px-6 2xl:px-10 ">
            <div className=" flex flex-row w-full justify-between h-[47px] ">
                <div className="w-[100%] ml-[48%] flex flex-row justify-between float-right">
                    <Filter
                        items={allYears}
                        informSelectedItem={setSelectedYear}
                        className={"float-right"}
                    />
                    <div className=" float-right">
                        {hasPrivileges("USER_INVOICE") && <BillsMainConsultant />}
                    </div>
                </div>
            </div>
            <div className=" flex  gap-1 ">
                {/*  */}
                <div className="flex flex-col  ">
                    <div className=" flex flex-col mb-firstDegree  ">
                        <Label
                            size="h2"
                            fontWeight="bold"
                            fontFamily=""
                            message="consultants.statement.overview.overview"
                            color="black"
                            className="font-700"
                        />
                        {user.role == "admin" && (
                            <Label
                                size="small"
                                fontWeight=""
                                fontFamily=""
                                message="consultants.statement.overview.doubleTapToEdit"
                                color="black"
                                className=""
                            />
                        )}
                    </div>
                    <ListOfEditableOverview
                        year={selectedYear}
                        yearData={yearData}
                        userActive={user}
                        className="w-[300px]"
                    />
                </div>
                {/* <div> */}
                <Separator
                    className={`${user.role == "admin"
                            ? "2xl:h-[118%] 2xl:mx-8 mx-4"
                            : "2xl:h-[100%] mx-8 "
                        } relative  h-[730px] w-[1px] `}
                    orientation="vertical"
                    color="secondaryBlue"
                    blur=""
                />
                {/* </div> */}
                <div className=" w-[100%] flex flex-col ">
                    <Label
                        size="h2"
                        fontWeight="bold"
                        fontFamily=""
                        message="consultants.statement.overflow.overflowFluctuation"
                        color="black"
                        className=""
                    />
                    <TableOverflow yearData={yearData} />

                    <div className="w-[100%]  max-h-[250px] h-full  bg-lighter">
                        <LineChartComp yearData={yearData} className="" />
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    );
}
