import Chip from "@/components/molecules/buttons/Chip";
//category : 0 is salary, 1 is bonus
//status : 0 is read, 1 is unread
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function ReadingStatus({ type, adminComment }) {

    
    switch (type) {
        case "DRAFT":
            return (<Chip item="consultants.timesheet.draft" bg="medium" fontWeight="bold" />);
        case "SUBMITTED":
            return (<Chip item="consultants.timesheet.submitted" bg="information" fontWeight="bold" />);
        case "APPROVED":
            return (<Chip item="consultants.timesheet.approved" bg="success" fontWeight="bold" />);
        case "REJECTED":
            return (
                <div className={`flex gap-firstDegree items-center `}>
                    <Chip item="consultants.timesheet.rejected" bg="error" fontWeight="bold" />
                    {
                        adminComment && (
                            <Tooltip title={adminComment} >
                                <InfoIcon fontSize="small" color="error" />
                            </Tooltip>
                        )
                    }
                </div>
            );
        default:
            break;
    }







}