// Actions.jsx
import React, { useState, useEffect } from 'react';
import { Label } from '@/components/atoms/texts/label';
import { cva } from 'class-variance-authority';
import { cn } from '@/lib/utils'; // Assurez-vous que le chemin d'importation de cn est correct

const Action = ({ icon, text, initialVariant = 'default', onClickAction, className, ...props }) => {
    const actionVariants = cva(
        'flex flex-col items-center justify-center space-y-4 p-4 rounded relative text-center ', 
        {
            variants: {
                variant: {
                    disabled: 'bg-lighter cursor-not-allowed border-2 border-transparent text-disabled',
                    default: 'bg-gradientCustomOpacity50 bg-accent text-primary cursor-pointer border border-transparent',
                    active: 'bg-gradientCustom bg-opacity-15  border-2 border-secondary text-primary'
                }

            },
            defaultVariants: {
                variant: 'default'
            }
        }
    );

    const [variant, setVariant] = useState(initialVariant);
    // Déterminer le fontWeight basé sur le variant
    const labelFontWeight = variant === 'active' ? 'bold' : 'regular';


    // Gère le changement de variant lorsque initialVariant change
    useEffect(() => {
        setVariant(initialVariant);
    }, [initialVariant]);

    // Fonction pour basculer entre les variants si le composant n'est pas disabled
    const toggleVariant = () => {
        if (variant !== 'disabled') {
            setVariant(prevVariant => prevVariant === 'default' ? 'active' : 'default');
        }
    };

    return (
        <div className={cn(actionVariants({ variant }), className)} onClick={initialVariant != "disabled" ? (onClickAction ? () => onClickAction() : () => toggleVariant()) : undefined} {...props}>
            {icon && <span className={`block `}>{icon}</span>}
            <Label message={text} fontWeight={labelFontWeight} className={`w-full truncate `} wFull="true"></Label>
        </div>
    );
};

export default Action;
