import { Label } from "@/components/atoms/texts/label";
import { Button } from "@/components/molecules/buttons/button";
import usePdfRequest from "./usePdfRequest";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { getCountryCode, getCountryData, getCountryDataList, getEmojiFlag } from 'countries-list'
import { continents, countries, languages } from 'countries-list'
import { useState } from "react";

import { Input } from "@/components/ui/input"
import { toast } from "@/components/organisms/feedback/use-toast";
import { Toaster } from "@/components/organisms/feedback/toaster";
import { useNavigate } from "react-router-dom";
import useAddProspectCV from "@/services/admin-services/useAddProspectCV";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import useUpdateProspectCV from "@/services/admin-services/useUpdateProspectCv";
import { useTranslation } from "react-i18next";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PrintSuccessMessage from "@/components/organisms/feedback/PrintSuccessMessage";

export default function WaitPdf({ handleReset, template, templateID }) {

    const { requestPDF, pdfFile, loading: loadingPDF, error: errorPDF } = usePdfRequest();
    // const { requestPDF, loading: loadingPDF, error: errorPDF } = usePdfRequest();
    // const pdfFile=""
    // const pdfFile = "https://artozouldesign.fr/CV_EloiseArtozoul_UX_UI_Designer-Developpeur-OpenToWorkOct23.pdf"
    const [languageForTemplate, setLanguageForTemplate] = useState("FR");
    const [titleForTemplate, setTitleForTemplate] = useState("");
    const [errorForm, setErrorForm] = useState();
    const navigate = useNavigate();
    const [t] = useTranslation("global");

    const { addNewProspectCV, data: dataAddTemplate, loading: loadingAddTemplate, error: errorAddTemplate } = useAddProspectCV();
    const { updateExistingProspectCV, data: updateData, loading: updateLoading, error: updateError } = useUpdateProspectCV();

    const topEuropeanLanguages = ['GB', 'DE', 'FR', 'IT', 'ES', 'NL'];

    // console.log("getEmojiFlag('FR') : ", getEmojiFlag('FR'));
    const addNewTemplate = async () => {
        if ((languageForTemplate && titleForTemplate && template)) {
            setErrorForm(false);
            console.log("WaitPdf ", template, languageForTemplate);
            const newTemplateId = await addNewProspectCV(template, languageForTemplate, titleForTemplate);
            if (newTemplateId) {
                toast({
                    variant: "success",
                    title: "success.templateAdded",
                    duration: 1500,
                })
                navigate("../")
            }
        } else {
            setErrorForm(true)
            toast({
                variant: "error",
                title: "error.cantBeNull",
                duration: 8000,
            })
        }


    }
    const updateTemplate = async () => {
        if ((languageForTemplate && titleForTemplate && template)) {
            setErrorForm(false);
            console.log("WaitPdf ", template, languageForTemplate);
            const receivedTemplateId = await updateExistingProspectCV(template, languageForTemplate, titleForTemplate);
            if (receivedTemplateId) {
                toast({
                    variant: "success",
                    title: "success.templateUpdated",
                    duration: 1500,
                })
                navigate("../")
            }
        } else {
            setErrorForm(true)
            toast({
                variant: "error",
                title: "error.cantBeNull",
                duration: 8000,
            })
        }
    }

    return (
        <div className='flex flex-col min-h-[55vh] justify-center items-center py-thirdDegree text-center gap-secondDegree w-full'>
            <Toaster />

            {!pdfFile && <>
                <Label message="consultants.cv.generateContext" />
                <div className="flex gap-secondDegree items-end">
                    <Select onValueChange={setLanguageForTemplate} value={languageForTemplate} >
                        <div className="flex flex-col items-start gap-1">
                            <Label className="text-primary" message="consultants.cv.mainLanguage" />
                            <SelectTrigger className={`w-[300px] bg-hoverLight ${errorForm && !languageForTemplate && `border-error`}`} >
                                <SelectValue placeholder={<Label message="global.actions.select" />} />
                            </SelectTrigger>
                        </div>

                        <SelectContent>
                            {Object.keys(countries)
                                .filter(code => topEuropeanLanguages.includes(code))
                                .map(code => {
                                    const country = countries[code];
                                    const emoji = getEmojiFlag(code.toUpperCase());
                                    const l = languages[country.languages[0]]?.name
                                    const countryInAppLanguage = t(`global.languages.${l.toLowerCase()}`)
                                    return <SelectItem key={code } value={code == "GB" ? "EN" : code}>{emoji + " " + countryInAppLanguage}</SelectItem>;
                                })}
                        </SelectContent>
                    </Select>

                    <Button text="global.actions.generate" onClick={() => { requestPDF(template, languageForTemplate) }}></Button>
                </div>
            </>}
            {loadingPDF &&
                <>
                    <Label message="consultants.cv.waitingPdf" />
                    <iframe src="https://lottie.host/embed/4a4bb743-f502-4351-9ed1-71191525c1f5/CpD3cpg3EG.json" className='h-50'></iframe>
                </>
            }
            {errorPDF && <PrintErrorMessage error={errorPDF} />}

            <div className="flex gap-thirdDegree w-full items-center">
                {errorAddTemplate && <PrintErrorMessage error={errorAddTemplate} />}

                {(pdfFile && !loadingPDF && !errorPDF) &&
                    <div className="w-full gap-secondDegree">
                        <PrintSuccessMessage success="success.done" className="mb-2" />
                        <iframe
                            src={pdfFile}
                            style={{
                                width: '100%',       // Le contenu s'adapte à la largeur de l'iframe
                                height: '50vh',       // Hauteur maximale de 50% de la hauteur de la fenêtre
                                maxHeight: '50vh',    // Hauteur maximale fixée
                                borderRadius: '8px'

                            }}
                        />

                    </div>}

                {(languageForTemplate && pdfFile) &&
                    <div className="flex flex-col gap-thirdDegree items-center bg-lighter p-thirdDegree rounded-sm shadow-md w-min h-min">
                        <Label message="consultants.cv.wantSaveTemplate"></Label>
                        <div className="flex flex-col items-start gap-1 w-full">
                            <Label className="text-primary" message="consultants.cv.titleTemplate" />
                            <Input className={` bg-white ${errorForm && !titleForTemplate && `border-error`}`} onChange={(e) => setTitleForTemplate(e.target.value)} value={titleForTemplate} />
                        </div>
                        <div className="flex gap-secondDegree">
                            {(!loadingAddTemplate && templateID && languageForTemplate && pdfFile) && <Button style="outline" text="consultants.cv.edit" onClick={() => updateTemplate()} />}
                            {(loadingAddTemplate) ? <span className="loader"></span> : (languageForTemplate && pdfFile && <Button style="" text="consultants.cv.newOne" onClick={() => addNewTemplate()} />)}
                        </div>
                    </div>
                }
            </div>

            <div className=" space-x-4 space-y-4">
                <Button style="ghost" onClick={handleReset} text="global.actions.reStart" className="w-fit" />
                <Button style="outline" onClick={() => { navigate("../") }} text="global.actions.backToMain"></Button>
            </div>



        </div>
    )
}




