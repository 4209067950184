import { useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import errorToText from '@/utils/errorToText';

const useSetUpOtp = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const setUpOtp = async (secret, otp) => {
        setLoading(true);
        try {
            const response = await fetch(window.ENV.BACKEND_URL+'/security-provider/api/secured/users/setupOTP', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.weakToken}`
                },
                body: JSON.stringify({ secret, otp })
            });

            if (!response.ok) {
                const errMessage = errorToText(response);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            setLoading(false);
            return { data, error: null };
        } catch (err) {
            setLoading(false);
            return { data: null, error: err.message };
        }
    };

    return { setUpOtp };
};

export default useSetUpOtp;
