import { Label } from "@/components/atoms/texts/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/molecules/popover";
import { Button } from "@/components/molecules/buttons/button";
import { useEffect, useState } from "react";
import Polygon1 from "@/assets/Polygon1.svg"
import ControlsDownload from "@/components/molecules/controlsDownload";
import Chip from "@/components/molecules/buttons/Chip";
// import ReadingStatus from "@/components/atoms/ReadingStatus";
import getCurrencySymbolFromCode from "@/utils/getCurrencySymbolFromCode";
import { useTranslation } from "react-i18next";
import getMonthName from "@/utils/getMonthName";
import ReadingStatus from "./readingStatus";
import { useDeletePayslip } from "@/services/admin-services/mutations/useDeletePayslip";
import { useGetPayslip } from "@/services/consultant-services/api-rest/useGetPayslip";
import { useGetConsultantPayslip } from "@/services/admin-services/api-rest/useGetConsultantPayslip";
import { useToast } from "@/components/organisms/feedback/use-toast";

// "amountTax": { "5": "7.36", "10": "4.53", "12": "7.68" },
export default function Payslip({ data, dataOwner, refetchFromScratch }) {

    const symbol = getCurrencySymbolFromCode(data?.currency)
    const [t] = useTranslation("global");
    const { toast } = useToast();

    // import hook for actions ---------------------------------
    const { deletePayslip, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeletePayslip();
    const { getPayslip, loading: loadingFile, error: errorFile, data: payslipFile } = dataOwner ? useGetConsultantPayslip() : useGetPayslip();


    // old payslip ? -------------------------------------------
    const providedDate = new Date(data?.createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - providedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // handle functions ----------------------------------------
    const handleGetPayslipFile = () => {
        if (data?.payslipFileUuid) {
            getPayslip(data?.payslipFileUuid);
        }
    };
    const handleOpenPayslipFile = () => {
        if (!payslipFile) {
            handleGetPayslipFile();
        }
        payslipFile && window.open(payslipFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }
    useEffect(() => {
        payslipFile && window.open(payslipFile?.url, "_blank")// Ouvrir l'URL dans un nouvel onglet
    }, [payslipFile]);


    const handleDelete = async () => {
        try {
            await deletePayslip({ variables: { uuid: data?.uuid } });
        } catch (e) {
            console.error("Error deleting handleDelete:", e);
        }
    };

    // TODO : texte à mettre dans global
    useEffect(() => {
        if (dataDelete) {
            toast({
                variant: "success",
                title: "success.backendOperation.delete",
                duration: 8000,
                description: "La fiche de paie " + data?.month + "/" + data?.year + " " + data?.comment + " a été supprimée"
            })
            refetchFromScratch(1)
        } else if (dataDelete?.deleteCompanyBenefitInvoice == false && errorDelete) {
            toast({
                variant: "error",
                title: "error.requestFailed",
                duration: 8000,
                description: errorDelete?.message
            })
        }
    }, [dataDelete])

    return (
        <div className={`bg-white  w-full shadow-md px-paddingLeftAndRight py-paddingTopAndBottom rounded-sm justify-start items-center inline-flex gap-secondDegree`}>
            <ReadingStatus isBonus={data?.bonus} />
            <ReadingStatus isNew={differenceInDays < 30} />

            <Label fontWeight={differenceInDays < 30 && "bold"}>{t("consultants.payslip.salary")} {getMonthName(data?.month)} {data?.year}</Label>

            <div className="grow"></div>
            <Label fontWeight="">{data?.comment}</Label>
            {dataOwner ?
                <div className=" overflow-hidden flex gap-firstDegree flex-col 2xl:flex-row">
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.employeeCharges" />
                        <Label >{data?.employeeContributions} {symbol}</Label>
                    </div><div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.employerCharges" />
                        <Label >{data?.employerContributions} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.grossSalary" />
                        <Label >{data?.grossSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.taxsOnSalary" />
                        <Label >{data?.taxes} {symbol}</Label>
                    </div>

                </div>
                :
                <div className="max-w-[70%] overflow-hidden flex gap-firstDegree flex-col xl:flex-row">
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.grossSalary" />
                        <Label >{data?.grossSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.netAmount" />
                        <Label >{data?.netSalary} {symbol}</Label>
                    </div>
                    <div className="flex gap-firstDegree">
                        <Label fontWeight={differenceInDays < 30 ? "bold" : "semibold"} message="consultants.payslip.taxsOnSalary" />
                        <Label >{data?.taxes} {symbol}</Label>
                    </div>
                </div>

            }

            <Label fontWeight="">{data?.fileSize}</Label>
            {(loadingDelete || loadingFile) && <span className="loader" />}
            <ControlsDownload
                dataOwner={dataOwner}
                onClickNewTab={handleOpenPayslipFile}
                onClickDownload={() => console.log("onClickDownload")}
                onClickDelete={handleDelete}

                disabledOpenNewTab={!data?.payslipFileUuid || errorFile}
                disabledDelete={errorDelete}

                hideDownload
                titleForDelete={t("consultants.payslip.salary") + " : " + getMonthName(data?.month) + " - " + data?.year}
            />

        </div>
    );
}