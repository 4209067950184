import React, { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/molecules/buttons/button";
import DescriptionItem from './DescriptionItem';
import SkillItem from "./SkillItem";
import ProjectItem from "./ProjectItem";
import ExperienceItem from "./ExperienceItem";
import CertificationItem from "./CertificationItem";
import EducationItem from "./EducationItem";
import JobTitleItem from "./JobTitleItem";
import { Label } from "@/components/atoms/texts/label";
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';

export const CustomKanban = ({ CVData, className, step, setTemplate, template }) => {
    const stepToDataPart = useMemo(() => ({
        0: 'descriptions',
        1: 'titles',
        2: 'mappedSkills',
        3: 'projects',
        4: 'experiences',
        5: 'certifications',
        6: 'educations',
        7: '*'
    }), []);

    const currentDataKey = stepToDataPart[step];
    console.log("custom kanbam, template : ", template, "currentDataKey", currentDataKey, "step", step);
    const CVData_copy = useTemplate(CVData[currentDataKey], step, template);
    // const transformedData = step === 2 ? transformMappedSkills(CVData[currentDataKey]) : CVData[currentDataKey];

    // const CVData_copy = useTemplate(transformedData, step, template);
    console.log("custom kanbam, CVData_copy : ", CVData_copy);

    // useEffect(() => {
    //     if (step === 0 || step === 1) {
    //         const ensureSelection = (key) => {
    //             if (!template?.blockSelected.some(item => item.uuid === CVData[key][0]?.uuid)) {
    //                 setTemplate(prevTemplate => ({
    //                     ...prevTemplate,
    //                     blockSelected: [...prevTemplate.blockSelected, { uuid: CVData[key][0]?.uuid }]
    //                 }));
    //             }
    //         };

    //         if (step === 0) {
    //             ensureSelection('descriptions');
    //         } else if (step === 1) {
    //             ensureSelection('titles');
    //         }
    //     }
    // }, [step, CVData, template, setTemplate]);

    return (
        <div className={` w-full text-neutral-50 ${className}`}>
            <Board CVData={CVData_copy} setTemplate={setTemplate} template={template} step={step} />
        </div>
    );
};
// const transformMappedSkills = (mappedSkills) => {
//     if (!Array.isArray(mappedSkills)) {
//       console.error('mappedSkills n\'est pas un tableau');
//       return mappedSkills;
//     }

//     return mappedSkills.map(item => {
//       if (item && typeof item === 'object' && item.skill) {
//         // Fusionner les propriétés de skill avec le reste de l'objet
//         return { ...item, ...item.skill };
//       }
//       return item;
//     });
//   };
const useTemplate = (CVData, stepToAdd = "", template) => {
    console.log("useTemplate, CVData : ", CVData, "stepToAdd : ", stepToAdd, "template : ", template);
    let updatedCVData = CVData.map(item => {
        const columnValue = template && template.blockSelected.some(schemaItem => schemaItem.uuid === item.uuid) ? "selected" : "other";
        const stepValue = stepToAdd;
        return { ...item, column: columnValue, step: stepValue };
    });

    if (stepToAdd === 0 || stepToAdd === 1) {
        const ensureSelection = (key) => {
            const alreadySelected = template?.blockSelected.some(item => CVData.some(dataItem => dataItem.uuid === item.uuid));
            if (!alreadySelected && !template?.blockSelected.some(item => item.uuid === CVData[0]?.uuid) && template?.blockSelected) {
                template.blockSelected.push({ uuid: CVData[0]?.uuid });
                updatedCVData = updatedCVData.map(item =>
                    item.uuid === CVData[0]?.uuid ? { ...item, column: 'selected' } : item
                );
            }
        };

        if (stepToAdd === 0) {
            ensureSelection('descriptions');
        } else if (stepToAdd === 1) {
            ensureSelection('titles');
        }
    }

    if (stepToAdd === 4 || stepToAdd === 5 || stepToAdd === 6) {
        updatedCVData = updatedCVData.map(item => ({ ...item, column: 'selected' }));
        template.blockSelected = [
            ...template.blockSelected,
            ...CVData.map(item => ({ uuid: item.uuid }))
        ];
    }

    console.log('updatedCVData', updatedCVData);
    return updatedCVData;
};

const Board = ({ CVData, setTemplate, template, step }) => {
    const [cVdata_copy, setCVdata_copy] = useState(CVData);
    const [actualStep, setActualStep] = useState(CVData);

    console.log('Board CVData', CVData, "cVdata_copy", cVdata_copy);

    useEffect(() => {
        if (actualStep !== step || !CVData) {
            setActualStep(step);
            setCVdata_copy(CVData);
        }
        if (step === 0 && CVData && CVData.length > 0) {
            setCVdata_copy(CVData);
        }
        console.log("Board useEffect CVData = ", CVData, "step = ", step);
    }, [CVData, step]);

    const transferAllCardsToSelected = () => {
        const updatedCVData = cVdata_copy.map(card => ({
            ...card,
            column: 'selected'
        }));
        setCVdata_copy(updatedCVData);

        setTemplate(prevTemplate => {
            const newBlockSelected = [...prevTemplate.blockSelected];
            updatedCVData.forEach(card => {
                if (!newBlockSelected.some(block => block.uuid === card.uuid)) {
                    newBlockSelected.push({ uuid: card.uuid });
                }
            });

            return {
                ...prevTemplate,
                blockSelected: newBlockSelected
            };
        });
    };

    const transferAllCardsToNotSelected = () => {
        const updatedCVData = cVdata_copy.map(card => ({
            ...card,
            column: 'other'
        }));
        setCVdata_copy(updatedCVData);

        const idsToRemove = cVdata_copy.map(card => card.uuid);

        setTemplate(prevTemplate => {
            const updatedBlockSelected = prevTemplate.blockSelected.filter(block =>
                !idsToRemove.includes(block.uuid)
            );

            return {
                ...prevTemplate,
                blockSelected: updatedBlockSelected
            };
        });
    };

    return (
        <div className="flex h-full w-full gap-3 overflow-auto ">
            <Column
                title="consultants.cv.other"
                column="other"
                headingColor="text-greyStrong"
                cards={cVdata_copy}
                setCards={setCVdata_copy}
                setTemplate={setTemplate}
                template={template}
            />
            <div className="flex flex-col gap-secondDegree h-full justify-center">
                <Button style="ghost" showLeftIcon leftIcon={<KeyboardDoubleArrowRightRoundedIcon />} showText={false} onClick={() => transferAllCardsToSelected()}>Select all</Button>
                <Button style="ghost" showLeftIcon leftIcon={<KeyboardDoubleArrowLeftRoundedIcon />} showText={false} onClick={() => transferAllCardsToNotSelected()}>Unselect all</Button>
            </div>

            <Column
                title="consultants.cv.selected"
                column="selected"
                headingColor="text-primary"
                cards={cVdata_copy}
                setCards={setCVdata_copy}
                setTemplate={setTemplate}
                template={template}
            />
        </div>
    );
};

const Column = ({ title, headingColor, cards, column, setCards, setTemplate, template }) => {
    const [active, setActive] = useState(false);
    console.log("custom kanban column cards = ", cards);
    const handleDragStart = (e, card) => {
        e.dataTransfer.setData("cardId", card.uuid);
    };

    const handleDragEnd = (e) => {
        const cardId = e.dataTransfer.getData("cardId");

        setActive(false);
        clearHighlights();

        const indicators = getIndicators();
        const { element } = getNearestIndicator(e, indicators);

        const before = element.dataset.before || "-1";
        const isMovingToSelected = element.dataset.column === "selected";

        if (before !== cardId) {
            let copy = [...cards];
            console.log(" handleDragEnd : copy = ", copy, "cardid = ", cardId);
            let cardToTransfer = copy.find((c) => c.uuid === cardId);
            if (!cardToTransfer) return;
            cardToTransfer = { ...cardToTransfer, column };

            copy = copy.filter((c) => c.uuid !== cardId);

            const moveToBack = before === "-1";

            if (moveToBack) {
                copy.push(cardToTransfer);
            } else {
                const insertAtIndex = copy.findIndex((el) => el.uuid === before);
                if (insertAtIndex === undefined) return;

                copy.splice(insertAtIndex, 0, cardToTransfer);
            }

            setCards(copy);
            setTemplate(prevTemplate => {
                let blockSelectedUpdated = [...prevTemplate.blockSelected];

                if (isMovingToSelected) {
                    if (!blockSelectedUpdated.some(block => block.uuid === cardId)) {
                        blockSelectedUpdated.push({ uuid: cardId });
                    }
                } else {
                    blockSelectedUpdated = blockSelectedUpdated.filter(block => block.uuid !== cardId);
                }

                return {
                    ...prevTemplate,
                    blockSelected: blockSelectedUpdated
                };
            });
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        highlightIndicator(e);

        setActive(true);
    };

    const clearHighlights = (els) => {
        const indicators = els || getIndicators();

        indicators.forEach((i) => {
            i.style.opacity = "0";
        });
    };

    const highlightIndicator = (e) => {
        const indicators = getIndicators();

        clearHighlights(indicators);

        const el = getNearestIndicator(e, indicators);

        el.element.style.opacity = "1";
    };

    const getNearestIndicator = (e, indicators) => {
        const DISTANCE_OFFSET = 50;

        const el = indicators.reduce(
            (closest, child) => {
                const box = child.getBoundingClientRect();

                const offset = e.clientY - (box.top + DISTANCE_OFFSET);

                if (offset < 0 && offset > closest.offset) {
                    return { offset: offset, element: child };
                } else {
                    return closest;
                }
            },
            {
                offset: Number.NEGATIVE_INFINITY,
                element: indicators[indicators.length - 1],
            }
        );

        return el;
    };

    const getIndicators = () => {
        return Array.from(document.querySelectorAll(`[data-column="${column}"]`));
    };

    const handleDragLeave = () => {
        clearHighlights();
        setActive(false);
    };

    const filteredCards = cards.filter((c) => c.column === column);
    return (
        <div className="flex flex-col w-[50%]">
            <div className="mb-3 flex items-center justify-between px-2">
                <Label className={headingColor} message={title} />
                <Label className={`${headingColor}`}>{filteredCards.length}</Label>
            </div>
            <div
                onDrop={handleDragEnd}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                className={`flex flex-col h-full w-full transition-colors rounded-sm py-1 px-2 ${active ? "bg-white" : "bg-lighter"
                    }`}
            >
                {filteredCards.map((c) => {
                    return <Card key={c.uuid} {...c} handleDragStart={handleDragStart} data={c} />;
                })}
                <DropIndicator beforeId={null} column={column} />
            </div>
        </div>
    );
};

const Card = ({ uuid, column, handleDragStart, step, data }) => {
    return (
        <>
            <DropIndicator beforeId={uuid} column={column} />
            <motion.div
                layout
                layoutId={uuid}
                draggable="true"
                onDragStart={(e) => handleDragStart(e, { uuid })}
                className="cursor-grab flex active:cursor-grabbing text-night "
            >
                {console.log("custom kanban step = ", step, "data = ", data)}
                {step === 0 && <DescriptionItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree  relative grow-0" />}
                {step === 1 && <JobTitleItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree w-full relative grow-0" />}
                {step === 2 && <SkillItem data={data} className="w-full" />}
                {step === 3 && <ProjectItem data={data} className="" />}
                {step === 4 && <ExperienceItem data={data} className="bg-white shadow-md rounded-sm flex flex-col px-secondDegree py-3 gap-firstDegree " />}
                {step === 5 && <CertificationItem data={data} className="" />}
                {step === 6 && <EducationItem data={data} className="" />}
            </motion.div>
        </>
    );
};

const DropIndicator = ({ beforeId, column }) => {
    return (
        <div
            data-before={beforeId || "-1"}
            data-column={column}
            className="my-0.5 h-0.5 w-full bg-secondary opacity-0"
        />
    );
};
