import Chip from "@/components/molecules/buttons/Chip";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function ReadingStatus({ type, adminComment }) {

    const marginClass = adminComment ? 'mr-[-20px]' : '';

    switch (type) {
        case "REJECTED":
            return (
                <div className={`flex gap-firstDegree items-center ${marginClass}`}>
                    <Chip item="consultants.expense.denied" bg="error" fontWeight="bold" />
                    {adminComment && (
                        <Tooltip title={adminComment} >
                            <InfoIcon fontSize="small" color="error"/>
                        </Tooltip>
                    )}
                </div>);
        case "SUBMITTED":
            return (
                <div className={`flex gap-firstDegree items-center ${marginClass}`}>
                    <Chip item="consultants.expense.inProgress" bg="information" fontWeight="bold" />{adminComment && (
                        <Tooltip title={adminComment} >
                            <InfoIcon fontSize="small"  color="information"/>
                        </Tooltip>
                    )}
                </div>);
        case "APPROVED":
            return (
                <div className={`flex gap-firstDegree items-center ${marginClass}`}>
                    <Chip item="consultants.expense.approved" bg="success" fontWeight="bold" />{adminComment && (
                        <Tooltip title={adminComment} >
                            <InfoIcon fontSize="small" color="success"/>
                        </Tooltip>
                    )}
                </div>);
        default:
            break;
    }

}