import { useState } from "react";
import errorToText from "@/utils/errorToText";

const useConsultantStatement = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [allData, setAllData] = useState(null);

    const getAllStatamentData = async (token, year, uuid) => {
        setLoading(true);
        setError(null);
        // console.log(
        //   "getAllStatamentData called with token:",
        //   token,
        //   "year:",
        //   year,
        //   "uuid:",
        //   uuid
        // );

        try {
            const response = await fetch(
                window.ENV.BACKEND_URL + "/api/graphql",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query: `query {        
                                    consultantStatement(
                                        statementRequest: { year: ${year}, consultantUuid: "${uuid}" }
                                    ) {
                                        dailyRateClient
                                        dailyRateConsultant
                                        daysSick
                                        daysWorked
                                        employeeContributions
                                        employerCost
                                        employerContributions
                                        expenses
                                        grossSalary
                                        companyBenefit
                                        netSalary
                                        overflow
                                        statementMonths {
                                            consultantServiceAmount
                                            cooptation
                                            currency
                                            dailyRateClient
                                            dailyRateConsultant
                                            daysSick
                                            daysWorked
                                            employeeContributions
                                            employerCost
                                            employerContributions
                                            expenses
                                            grossSalary
                                            companyBenefit
                                            month
                                            netSalary
                                            commission
                                            overflow
                                            overflowBalance
                                            taxes
                                            uuid
                                            cooptations {
                                                amount
                                                cooptedName
                                                cooptedUuid
                                                currency
                                                percentage
                                                uuid
                                            }
                                        }
                                        taxes
                                        year
                                    }
                                    }`,
                    }),
                }
            );

            if (!response.ok) {
                const errMessage = errorToText(response);
                console.error("Server responded with an error:", errMessage);
                setLoading(false);
                throw new Error(errMessage);
            }

            const data = await response.json();
            //   console.log("Server response:", data);

            if (data.errors) {
                const errMessage = data.errors[0].message;
                console.error("GraphQL error:", errMessage);
                setLoading(false);
                throw new Error(errMessage);
            }

            setAllData(data.data.consultantStatement);
            setLoading(false);
            return { data: data.data.consultantStatement, error: null };
        } catch (err) {
            console.error("getAllStatamentData error:", err);
            setError(err.message);
            setLoading(false);
            return { data: null, error: err.message };
        }
    };

    return { getAllStatamentData, allData, error, loading };
};

export default useConsultantStatement;
