export const availableRoutes = [

    // Routes pour l'authentification et la redirection
    { url: '/login', available: true, requiredPrivileges: [] },
    { url: '/logout', available: true, requiredPrivileges: [] },
    { url: '/activate', available: true, requiredPrivileges: [] },
    { url: '/resetPassword', available: true, requiredPrivileges: [] },
    { url: '/', available: true, requiredPrivileges: [] }, // Root redirect

    // -------------------- Vision Admin --------------------
    { url: '/consultant', available: true, requiredPrivileges: [] },
    { url: '/consultant/:id', available: true, requiredPrivileges: ["ADMIN_USERS"] },
    { url: '/consultant/:id/statement', available: true, requiredPrivileges: ["ADMIN_STATEMENT"] },
    { url: '/consultant/:id/information', available: true, requiredPrivileges: ["ADMIN_USER_INFO_LIGHT", "ADMIN_USER_INFO_FULL"] },
    { url: '/consultant/:id/cv', available: false, requiredPrivileges: ["ADMIN_CV"] },
    { url: '/consultant/:id/cv/generate', available: false, requiredPrivileges: ["ADMIN_CV"] },
    { url: '/consultant/:id/cv/generate/:templateID', available: false, requiredPrivileges: ["ADMIN_CV"] },
    { url: '/consultant/:id/payslip', available: true, requiredPrivileges: ["ADMIN_PAYSLIP"] },
    { url: '/consultant/:id/timesheet', available: true, requiredPrivileges: ["ADMIN_TIMESHEET"] },
    { url: '/consultant/:id/skills', available: false, requiredPrivileges: ["ADMIN_SKILLS"] },
    { url: '/consultant/:id/expense', available: true, requiredPrivileges: ["ADMIN_EXPENSE"] },
    { url: '/consultant/:id/companyBenefit', available: true, requiredPrivileges: ["ADMIN_COMPANY_BENEFIT"] },

    // Routes d'administration globales
    { url: '/overflowOptimization', available: true, requiredPrivileges: [] }, 
    { url: '/statistics', available: false, requiredPrivileges: [] }, 
    { url: '/finance', available: false, requiredPrivileges: [] }, 

    // Routes de gestion des prospects
    { url: '/prospects', available: true, requiredPrivileges: ["ADMIN_PROSPECTS"] },
    { url: '/prospects/cv', available: true, requiredPrivileges: ["ADMIN_PROSPECTS"] },
    { url: '/prospects/cv/:prospectID', available: true, requiredPrivileges: ["ADMIN_PROSPECTS"] },
    { url: '/prospects/cv/:prospectID/generate', available: true, requiredPrivileges: ["ADMIN_PROSPECTS"] },
    { url: '/prospects/cv/:prospectID/generate/:templateID', available: true, requiredPrivileges: ["ADMIN_PROSPECTS"] },

    // -------------------- Vision Consultant --------------------
    { url: '/administration', available: true, requiredPrivileges: [] }, 
    { url: '/administration/statement', available: true, requiredPrivileges: ["USER_STATEMENT"] }, 
    { url: '/administration/information', available: true, requiredPrivileges: ["OWN_PROFILE"] },
    { url: '/administration/cv', available: false, requiredPrivileges: ["USER_CV"] }, 
    { url: '/administration/cv/generate', available: false, requiredPrivileges: ["USER_CV"] }, 
    { url: '/administration/cv/generate/:templateID', available: false, requiredPrivileges: ["USER_CV"] }, 
    { url: '/administration/payslip', available: true, requiredPrivileges: ["USER_PAYSLIP"] },
    { url: '/administration/skills', available: false, requiredPrivileges: ["USER_SKILLS"] },  
    { url: '/administration/expense', available: true, requiredPrivileges: ["USER_EXPENSE"] },
    { url: '/administration/companyBenefit', available: true, requiredPrivileges: ["USER_COMPANY_BENEFIT"] },
    { url: '/administration/timesheet', available: true, requiredPrivileges: ["USER_TIMESHEET"] },

    // Autres routes pour les consultants
    { url: '/profitSharing', available: false, requiredPrivileges: [] },
    { url: '/companyValuation', available: false, requiredPrivileges: [] },
    { url: '/overflow', available: true, requiredPrivileges: [] },

    // -------------------- Page d'erreur pour les mobiles et erreurs générales --------------------
    { url: '*', available: true, requiredPrivileges: [] },
];