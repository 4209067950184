import { useAuth } from "@/context/AuthContext";

// variantLogic.js
export const getVariantItems = (user) => {
    const { isAuth, isBrowsingAllowedTo, viewAs } = useAuth();

    const itemsByRole = {
        admin: [
            { name: "navbarAdmin.consultant", path: "/consultant", disabled: !isBrowsingAllowedTo("/consultant")},
            { name: "prospects.prospects", path: "/prospects", disabled: !isBrowsingAllowedTo("/prospects")},
            { name: "navbarAdmin.finance", path: "/finance"  , disabled: !isBrowsingAllowedTo("/finance")},
            { name: "navbarAdmin.statistics", path: "/statistics"  , disabled: !isBrowsingAllowedTo("/statistics")},
            { name: "navbarAdmin.overflowOptimization", path: "/overflowOptimization",   disabled: !isBrowsingAllowedTo("/overflowOptimization")},
        ],
        consultant: [
            { name: "navbarConsultant.administration", path: "/administration" , disabled: !isBrowsingAllowedTo("/administration")},
            { name: "navbarConsultant.profitSharing", path: "/profitSharing"  , disabled: !isBrowsingAllowedTo("/profitSharing")},
            { name: "navbarConsultant.compagnyValuation", path: "/companyValuation"  , disabled: !isBrowsingAllowedTo("/companyValuation")},
            { name: "navbarConsultant.overflowOptimization", path: "/overflow" , disabled: !isBrowsingAllowedTo("/overflow") },
        ],
    };
    
    if (isAuth()) {
         console.log("returning user variants based on viewAs", viewAs);
        if (viewAs == 'ADMIN_') {
            return itemsByRole.admin;
        } else if (viewAs == 'USER_') {
            return itemsByRole.consultant;
        } else {
            return [];
        }
    }
    return;

};
