import { useEffect, useState } from "react";

export const useFormEditBills = (editData, nameInUserFormated) => {
    const [consultantUuid, setConsultantUuid] = useState(nameInUserFormated || "");
    const [customer, setCustomer] = useState(editData?.clientName || "");
    const [billNumber, setBillNumber] = useState(editData?.invoiceNumber || "");
    const [days, setDays] = useState(editData?.daysWorked || 0);
    const [amountClient, setAmountClient] = useState(editData?.amountClient || 0);
    const [amountConsultant, setAmountConsultant] = useState(editData?.amountConsultant || 0);
    const [currency, setCurrency] = useState(editData?.currency || "EUR");
    const [tJMClient, setTJMClient] = useState(editData?.dailyRateClient || 0);
    const [tJMConsultant, setTJMConsultant] = useState(editData?.dailyRateConsultant || 0);
    const [periodStart, setPeriodStart] = useState(editData?.periodStart || "");
    const [periodEnd, setPeriodEnd] = useState(editData?.periodEnd || "");
    const [invoiceDate, setInvoiceDate] = useState(editData?.invoiceDate || "");
    const [commission, setCommission] = useState(editData?.commission || 0);
    const [manualCommission, setManualCommission] = useState(editData?.manualCommission || false);

    const [consultantUuidError, setConsultantUuidError] = useState(null);
    const [customerError, setCustomerError] = useState(null);
    const [billNumberError, setBillNumberError] = useState(null);
    const [daysError, setDaysError] = useState(null);
    const [amountClientError, setAmountClientError] = useState(null);
    const [amountConsultantError, setAmountConsultantError] = useState(null);
    const [currencyError, setCurrencyError] = useState(null);
    const [tJMClientError, setTJMClientError] = useState(null);
    const [tJMConsultantError, setTJMConsultantError] = useState(null);
    const [periodStartError, setPeriodStartError] = useState(null);
    const [periodEndError, setPeriodEndError] = useState(null);
    const [invoiceDateError, setInvoiceDateError] = useState(null);
    const [commissionError, setCommissionError] = useState(null);

    const [formIsValid, setFormIsValid] = useState(true);


    useEffect(() => {
        setAmountClient(tJMClient * days)
    }, [tJMClient, days]);

    useEffect(() => {
        setAmountConsultant(tJMConsultant * days)
    }, [tJMConsultant, days]);

    // Validation targeting specific fields
    useEffect(() => {
        (consultantUuid || consultantUuidError != null) && validateForm("consultantUuidErrorTest");
    }, [consultantUuid]);

    useEffect(() => {
        (customer || customerError != null) && validateForm("customerErrorTest");
    }, [customer]);

    useEffect(() => {
        (billNumber || billNumberError != null) && validateForm("billNumberErrorTest");
    }, [billNumber]);

    useEffect(() => {
        (days || daysError != null) && validateForm("daysErrorTest");
    }, [days]);


    useEffect(() => {
        (currency || currencyError != null) && validateForm("currencyErrorTest");
    }, [currency]);

    useEffect(() => {
        // console.log("use effect tjmclient", tJMClient || tJMClientError, tJMClient, tJMClientError);
        (tJMClient || tJMClientError != null) && validateForm("tJMClientErrorTest");
    }, [tJMClient]);

    useEffect(() => {
        (tJMConsultant || tJMConsultantError != null) && validateForm("tJMConsultantErrorTest");
    }, [tJMConsultant]);

    useEffect(() => {
        (periodStart || periodStartError != null) && validateForm("periodStartErrorTest");
    }, [periodStart]);

    useEffect(() => {
        (periodEnd || periodEndError != null) && validateForm("periodEndErrorTest");
    }, [periodEnd]);

    useEffect(() => {
        (invoiceDate || invoiceDateError != null) && validateForm("invoiceDateErrorTest");
    }, [invoiceDate]);

    useEffect(() => {
        (commission || commissionError != null) && validateForm("commissionErrorTest");
    }, [commission]);

    useEffect(() => {
        setCommission(editData?.commission || 0);
    }, [manualCommission]);
    
    // Reset form to default values
    const resetForm = () => {
        setConsultantUuid("");
        setCustomer("");
        setBillNumber("");
        setDays(0);
        setAmountClient(0);
        setAmountConsultant(0);
        setCurrency("EUR");
        setTJMClient(0);
        setTJMConsultant(0);
        setPeriodStart("");
        setPeriodEnd("");
        setInvoiceDate("");
        setConsultantUuidError(null);
        setCustomerError(null);
        setBillNumberError(null);
        setDaysError(null);
        setAmountClientError(null);
        setAmountConsultantError(null);
        setCurrencyError(null);
        setTJMClientError(null);
        setTJMConsultantError(null);
        setPeriodStartError(null);
        setPeriodEndError(null);
        setInvoiceDateError(null);
        setCommission(0);
        setManualCommission(true);
    };

    // Validation logic
    function validateForm(varToTest) {
        switch (varToTest) {
            case "all":
                setConsultantUuidError(!consultantUuid);
                setCustomerError(!customer);
                setBillNumberError(!billNumber);
                setDaysError(!days);

                setCurrencyError(!currency);
                setTJMClientError(!tJMClient);
                setTJMConsultantError(!tJMConsultant);
                setPeriodStartError(!periodStart);
                setPeriodEndError(!periodEnd);
                setInvoiceDateError(!invoiceDate);
                setCommissionError( commission < 0);

                break;
            case "consultantUuidErrorTest":
                setConsultantUuidError(!consultantUuid);
                break;
            case "customerErrorTest":
                setCustomerError(!customer);
                break;
            case "billNumberErrorTest":
                setBillNumberError(!billNumber);
                break;
            case "daysErrorTest":
                setDaysError(!days || days <= 0);
                break;
            case "currencyErrorTest":
                setCurrencyError(!currency);
                break;
            case "tJMClientErrorTest":
                setTJMClientError(!tJMClient || tJMClient <= 0);
                break;
            case "tJMConsultantErrorTest":
                setTJMConsultantError(!tJMConsultant || tJMConsultant <= 0);
                break;
            case "periodStartErrorTest":
                setPeriodStartError(!periodStart);
                break;
            case "periodEndErrorTest":
                setPeriodEndError(!periodEnd);
                break;
            case "invoiceDateErrorTest":
                setInvoiceDateError(!invoiceDate);
                break;
            case "commissionErrorTest":
                setCommissionError( commission < 0);
                break;

            default:
                console.error("Unknown validation test:", varToTest);
                break;
        }

        // Overall form validation
        if (!consultantUuid || !customer || !billNumber || !days || !amountClient || !amountConsultant || !currency || !tJMClient || !tJMConsultant || !periodStart || !periodEnd || !invoiceDate ) {
            return false;
        }
        return true;
    }
    return {
        consultantUuid,
        customer,
        billNumber,
        days,
        amountClient,
        amountConsultant,
        currency,
        tJMClient,
        tJMConsultant,
        periodStart,
        periodEnd,
        invoiceDate,
        commission,
        manualCommission,
        consultantUuidError,
        customerError,
        billNumberError,
        daysError,
        amountClientError,
        amountConsultantError,
        currencyError,
        tJMClientError,
        tJMConsultantError,
        periodStartError,
        periodEndError,
        invoiceDateError,
        commissionError,
        formIsValid,
        setConsultantUuid,
        setCustomer,
        setBillNumber,
        setDays,
        setAmountClient,
        setAmountConsultant,
        setCurrency,
        setTJMClient,
        setTJMConsultant,
        setPeriodStart,
        setPeriodEnd,
        setInvoiceDate,
        setCommission,
        setManualCommission,
        validateForm,
        resetForm,

    };
};
