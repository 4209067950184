import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { useState } from 'react';
import QRCode from 'qrcode';

const useLogin = () => {
    const navigate = useNavigate();
    const { simulateLogin } = useAuth();

    const handleLogin = (role) => {
        simulateLogin(role);
        // Rediriger en fonction du rôle
        if (role === 'admin') {
            navigate('/consultant');
        } else if (role === 'consultant') {
            navigate('/administration');
        }
    };

    const initPassword = (password) => {
        // POST https://kubrik21-backend-dev.synapse42.com/security-provider/api/secured/users/changePassword
        //     Content - Type: application / json
        //     Authorization: Bearer token de l'url

        //     {
        //         "newPassword": "New42%Password"
        //     }
        // console.log("initPassword, password = ", password)
    }

    const setUpOTP = (code)=>{

    }

    return { handleLogin, initPassword };

};

export default useLogin;
