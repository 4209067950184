// Rôle requis : USER_TIMESHEET
// Ce hook permet à l'utilisateur de récupérer la liste de ses timesheets.
// Seuls les utilisateurs ayant le rôle USER_TIMESHEET peuvent accéder à cette requête.

import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@/context/AuthContext";
import { useEffect } from "react";

const GET_TIMESHEETS = gql`
  query Timesheets($sliceNumber: Int!) {
    timesheets(timesheetsRequest: { sliceNumber: $sliceNumber }) {
      timesheets {
        uuid
        clientName
        year
        month
        status
        daysWorked
        daysVacation
        daysIllness
        daysTraining
        adminComment
      }
      hasNext
    }
  }
`;

export const useTimesheets = (sliceNumber) => {
    const { user } = useAuth();
    const { loading, error, data, refetch } = useQuery(GET_TIMESHEETS, {
        variables: { sliceNumber },
        context: {
            headers: {
                Authorization: `Bearer ${user?.strongToken}`,
            },
        },

    });

    useEffect(() => {
        refetch()
    }, [sliceNumber])

    return { loading, error, data: data?.timesheets, refetch };
};
