/* eslint-disable react/prop-types */

/**
 * @component TableOverflow
 *
 * The `TableOverflow` component displays a table summarizing monthly statement data and provides a yearly summary. It calculates totals for specific metrics and shows detailed rows of data for each month.
 *
 * Key Features:
 * - Displays monthly statement data in a table format.
 * - Calculates and shows totals for employer cost, customer payments, and cooptation.
 * - Provides a yearly summary row at the bottom of the table.
 *
 * Props:
 * - @param {Object} yearData - Data for the selected year, including monthly statement data.
 *
 * Local State:
 * - This component does not manage its own state.
 *
 * Computed Values:
 * - `totalEmployerCost` (number): Total employer cost for the year.
 * - `totalCustomerPayment` (number): Total customer payment for the year.
 * - `totalCooptation` (number): Total cooptation for the year.
 * - `annualRow` (Array): Array representing the annual summary row with totals for employer cost, customer payment, and cooptation.
 *
 * Usage:
 * - This component is used to present a detailed table of statement data for various months and include a summary row for the entire year. It is suitable for use in dashboards or detailed report views where users need to see monthly and annual summaries of their statement data.
 *
 * Example:
 * ```jsx
 * <TableOverflow yearData={yearData} />
 * ```
 *
 * Dependencies:
 * - @component TableHead: Component for displaying the header of the table.
 * - @component TableRow: Component for displaying rows of data.
 * - @mui/icons-material: Icons used in the table header.
 * - @utils transformUserData: Utility functions for data transformation.
 *
 * Functions:
 * - `enrichMonthlyData(data)`: Transforms and enriches monthly data for display in the table.
 * - `transformStatementData(data)`: Transforms statement data for display in table rows.
 *
 * @example
 * const yearData = {
 *   statementMonths: [
 *     { month: 'January', employerCost: 1000, clientPayment: 1200, cooptation: 200 },
 *     { month: 'February', employerCost: 1100, clientPayment: 1300, cooptation: 250 },
 *     // ... more monthly data
 *   ]
 * };
 *
 * <TableOverflow yearData={yearData} />
 * ```
 */

import React from "react";
import TableRow from "./DataTable/TableRow";
import MovingIcon from "@mui/icons-material/Moving";
import TableHead from "./DataTable/TableHead";
import { enrichMonthlyData } from "@/utils/transformUserData";
import { transformStatementData } from "@/utils/transformUserData";


const tablehead = [
  { employerCost: "consultants.statement.overflow.tableHead.employerCost" },
  {
    customerPaiment: "consultants.statement.overflow.tableHead.customerPaiment",
  },
  { cooptation: "consultants.statement.overflow.tableHead.cooptation" },
  { overflow: "consultants.statement.overflow.tableHead.overflow" },
];
export default function TableOverflow({  yearData }) {
  //const yearlyData = getYearDataForUser(user, year);

  const userMonthData = yearData ? yearData.statementMonths: null;

  let totalEmployerCost = 0;
  let totalCustomerPayment = 0;
  let totalCooptation = 0;

  // Calcul des totaux
  userMonthData && userMonthData.forEach((monthData) => {
    totalEmployerCost += monthData.employerCost || 0;
    totalCustomerPayment += monthData.clientPayment || 0;
    totalCooptation += monthData.cooptation || 0;
  });

  // Création de la ligne annuelle avec les totaux
  const annualRow = [
    "annuel",
    totalEmployerCost,
    totalCustomerPayment,
    totalCooptation,
    "",
  ];

  // Utilisa
  return (
    <div className="w-full py-2 ">
      <div className="w-full">
        <TableHead
          tableHeadData={tablehead}
          indexContainingIcon={3}
          indexWithSpecificColor={3}
          colorForTheSpecificIndex="primaryLinear"
          colorsOfAllcells="grey"
          icon={<MovingIcon className="mr-2" color="white"/>}
        />
        {/* </div> */}
      </div>

      {userMonthData && enrichMonthlyData(userMonthData).map((month, index) => (
        <TableRow
          key={index}
          rowData={Object.values(transformStatementData(month))}
          enableHover={true}
          indexOfCellWithSpecificColor={4}
          colorOfSpecificCell="primaryLight"
          indexOfCellWithSpecificBorder={0}
          borderName={"left"}
          tableOfIndexOfCellsToTranslate={[0]}
          makeTansparentFirstCell={true}
          userMonthdata={userMonthData}
          isPrintedOnStatement={true}
          // onRowDataChange={(newData) => handleMonthDataChange(newData, index)}
        />
      ))}
      <div>
        {" "}
        <TableRow
          rowData={annualRow}
          indexOfCellWithSpecificBorder={0}
          borderName={"left"}
          tableOfIndexOfCellsToTranslate={[0]}
          //onRowDataChange={(newData) => handleMonthDataChange(newData, monthlyData.length)}
        />
      </div>
    </div>
  );
}
